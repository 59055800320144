<template>
    <div id="equipment-page">
        <toolbar-component v-bind:title="$t('left_navbar_component.assets')" />

        <v-tabs
            v-if="asset_types.length + $store.getters['asset_module/asset_modules'].length > 0"
            v-model="tab_selected"
            active-class="primary--text"
            background-color="secondary"
            class="tabs-asset-types default-arrows-tabs"
            show-arrows
            slider-color="primary"
        >
            <template v-if="asset_types.some((asset_type) => asset_type.key === 'cuve')">
                <v-tab
                    :class="tab_selected === 'equipment-tank' ? 'primary--text' : 'primary--text tab-opacity'"
                    data-cy="tab-tank"
                    href="#equipment-tank"
                >
                    <tank-svg />
                    <span class="ml-2 primary--text">{{ $t('equipment_page.tank') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-tank">
                    <list-tank-equipment-component :asset_type="asset_types.filter((asset_type) => asset_type.key === 'cuve')[0]" />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'silo')">
                <v-tab :class="tab_selected === 'equipment-silo' ? 'primary--text' : 'primary--text tab-opacity'" href="#equipment-silo">
                    <silo-svg />
                    <span class="primary--text">Silos</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-silo">
                    <list-silo-equipment-component />
                </v-tab-item>
            </template>

            <template v-if="asset_types.some((asset_type) => asset_type.key === 'chaudiere')">
                <v-tab
                    :class="tab_selected === 'equipment-boiler' ? 'primary--text' : 'primary--text tab-opacity'"
                    href="#equipment-boiler"
                >
                    <boiler-svg />
                    <span class="primary--text">{{ $t('equipment_page.boiler') }}</span>
                </v-tab>
                <v-tab-item class="background" value="equipment-boiler">
                    <list-boiler-equipment-component />
                </v-tab-item>
            </template>

            <template
                v-for="module in $store.getters['asset_module/asset_modules'].filter((item) =>
                    userHasAccess(item.designation, role_vision)
                )"
            >
                <v-tab
                    :key="module.id_module"
                    :class="`${tab_selected === module.designation ? 'primary--text' : 'primary--text tab-opacity'}`"
                    :href="`#${module.designation}`"
                >
                    <div class="mr-1 primary--text display-flex-align-center gap-1">
                        <custom-icon-component :icon="module.menu_icon" class="font-26" color="primary" default_icon="mdi-remote" />
                        {{ $t(`modules.${module.designation}`) }}
                    </div>
                </v-tab>

                <v-tab-item :key="module.id_module" :value="module.designation" class="background">
                    <list-asset-component :asset_module="module" />
                </v-tab-item>
            </template>
        </v-tabs>

        <template v-else-if="asset_types.length === 1">
            <list-tank-equipment-component
                v-if="asset_types[0].key === 'cuve'"
                :asset_type="asset_types.filter((asset_type) => asset_type.key === 'cuve')[0]"
            />

            <list-boiler-equipment-component v-if="asset_types[0].key === 'chaudiere'" />

            <list-silo-equipment-component v-if="asset_types[0].key === 'silo'" />
        </template>
    </div>
</template>

<script>
import ToolbarComponent from '@/components/Global/ToolbarComponent'

import ListBoilerEquipmentComponent from '@/components/Equipment/ListBoilerEquipmentComponent'
import ListSiloEquipmentComponent from '@/components/Equipment/ListSiloEquipmentComponent'
import ListTankEquipmentComponent from '@/components/Equipment/ListTankEquipmentComponent'

import BoilerSvg from '@/components/Svg/BoilerSvg'
import SiloSvg from '@/components/Svg/SiloSvg'
import TankSvg from '@/components/Svg/TankSvg'
import ListAssetComponent from '@/components/Equipment/Generic/ListAssetComponent.vue'
import CustomIconComponent from '@/components/Global/CustomIconComponent.vue'

export default {
    name: 'EquipmentPage',
    components: {
        CustomIconComponent,
        ListAssetComponent,
        ToolbarComponent,
        ListTankEquipmentComponent,
        BoilerSvg,
        SiloSvg,
        TankSvg,
        ListBoilerEquipmentComponent,
        ListSiloEquipmentComponent,
    },
    data() {
        return {
            tab_selected: '',
        }
    },
    computed: {
        asset_types: {
            get() {
                return this.$store.getters['asset_types/asset_types_which_have_asset']
            },
            set() {
                this.getProfessionalAssetTypes()
            },
        },
    },
    mounted() {
        this.$pushy.register()
        this.getNotifications()
    },
}
</script>

<style>
.v-slide-group__prev {
    display: none !important;
}
</style>
