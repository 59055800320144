<template>
    <div id="asset-devices-information-widget-component">
        <widget-wrapper-component
            :title="$tc('asset_devices_information_widget_component.devices_information', [devices.length])"
            class="overflow-hidden position-relative"
        >
            <template v-slot:default>
                <template v-if="devices.length > 0">
                    <v-tabs v-model="selected_device" class="mb-2" v-on:change="handleDeviceSelectUpdate">
                        <v-tab
                            v-for="(device, index) in devices"
                            :key="device.id_device"
                            :class="selected_device === index ? 'font-weight-bold' : ''"
                            :value="index"
                        >
                            {{ device.device_number }}
                        </v-tab>
                    </v-tabs>

                    <v-slide-x-transition hide-on-leave>
                        <div v-if="!refresh" class="mt-4 text-sm">
                            <div class="pa-2 d-flex flex-column gap-2">
                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-tag</v-icon>
                                        {{ $t('asset_devices_information_widget_component.name') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ device.device_referentiel.name }}</span>
                                    <br />
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-chip</v-icon>
                                        {{ $t('asset_devices_information_widget_component.device_number') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ device.device_number }}</span>
                                    <br />
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-access-point</v-icon>
                                        {{ $t('information_device_component.network') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ device.network_display }}</span>
                                    <br />
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-cube-send</v-icon>
                                        {{ $t('asset_devices_information_widget_component.source_data') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ device.operator_type }}</span>
                                    <br />
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-check-network-outline</v-icon>
                                        {{ $t('information_device_component.total_statement_per_day') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ device.total_statement_per_day }}</span>
                                    <br />
                                </div>

                                <div>
                                    <v-row class="ma-0">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-access-point-network</v-icon>
                                            {{ $t('information_device_component.date_last_emission') }} :
                                        </span>
                                        <chips-last-statement
                                            :date="device.date_last_emission"
                                            :fallback_text="$t('error_display.no_emission')"
                                            class="ml-1"
                                        />
                                    </v-row>
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-battery-70</v-icon>
                                        {{ $t('information_device_component.battery') }} :
                                    </span>
                                    <span v-if="device.battery" class="font-weight-bold">{{ device.battery }} %</span>
                                    <v-icon v-else dense> mdi-battery-unknown</v-icon>
                                    <br />
                                </div>

                                <div v-if="device.is_bug_hardware">
                                    <span class="font-color-light warning--text">
                                        <v-icon class="mr-1 warning--text" dense>mdi-alert-outline</v-icon>
                                        {{ $t('information_device_component.bug_device_detected') }}
                                    </span>
                                </div>

                                <template v-if="device.orientation">
                                    <div>
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-compass-outline</v-icon>
                                            {{ $t('information_device_component.orientation') }} :
                                        </span>
                                        <span class="font-weight-bold">{{ displayToHumanOrientation(device.orientation) }}</span>
                                        <br />
                                    </div>
                                </template>

                                <div
                                    v-if="
                                        device.is_sentinel_active ||
                                        device.is_threshold_low_active ||
                                        device.is_threshold_high_active ||
                                        device.is_integrated_device ||
                                        device.is_treatment_adblue
                                    "
                                >
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-cogs</v-icon>
                                        {{ $t('information_device_component.activated_options') }} :
                                        <br />
                                    </span>

                                    <span v-if="device.is_sentinel_active" class="font-weight-bold ml-8">
                                        - {{ $t('information_device_component.sentinel_mode') }}
                                        <br />
                                    </span>

                                    <span v-if="device.is_threshold_low_active" class="font-weight-bold ml-8">
                                        - {{ $t('information_device_component.low_threshold') }}
                                        <br />
                                    </span>

                                    <span v-if="device.is_threshold_high_active" class="font-weight-bold ml-8">
                                        - {{ $t('information_device_component.high_threshold') }}
                                        <br />
                                    </span>

                                    <span v-if="device.is_integrated_device" class="font-weight-bold ml-8">
                                        - {{ $t('information_device_component.integrated_device') }}
                                        <br />
                                    </span>

                                    <span v-if="device.is_treatment_adblue" class="font-weight-bold ml-8">
                                        - AdBlue
                                        <br />
                                    </span>
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                        {{ $t('information_device_component.version') }} :
                                    </span>
                                    <br />

                                    <span class="font-color-light ml-7"> {{ $t('information_device_component.version_hardware') }} : </span>
                                    <span class="font-weight-bold">{{ device.version_hardware }}</span>
                                    <br />

                                    <span class="font-color-light ml-7"> {{ $t('information_device_component.version_software') }} : </span>
                                    <span class="font-weight-bold">{{ device.version_software }}</span>
                                    <br />
                                </div>

                                <v-divider class="my-1" />

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-credit-card-settings-outline</v-icon>
                                        {{ $t('information_device_component.subscribe') }} :
                                    </span>

                                    <span v-if="device.contract_paid_by_professional" class="font-weight-bold">
                                        {{ $t('information_device_component.subscribe_handle_by') }}
                                        {{ device.contract.professional.distributor_name }}
                                    </span>

                                    <template v-else>
                                        <span class="font-weight-bold">
                                            {{
                                                $t('information_device_component.date_from_to', [
                                                    moment(device.date_subscription_start).format(date_format),
                                                    moment(device.date_subscription_end).format(date_format),
                                                ])
                                            }}
                                        </span>
                                        <br />
                                        <p class="font-italic font-12 ml-7 mb-1 position-relative" style="bottom: 5px">
                                            {{
                                                new Date(device.date_subscription_end) > new Date()
                                                    ? $t('information_device_component.subscribe_end_at', [
                                                          moment(device.date_subscription_end).fromNow(),
                                                      ])
                                                    : $t('information_device_component.subscribe_end_until', [
                                                          moment(device.date_subscription_end).fromNow(),
                                                      ])
                                            }}
                                        </p>
                                    </template>
                                </div>

                                <div>
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                        {{ $t('information_device_component.date_activation') }} :
                                    </span>
                                    <br />

                                    <span class="font-color-light ml-7">
                                        {{ $t('information_device_component.date_activation_interface') }} :
                                    </span>
                                    <span class="font-weight-bold">
                                        {{
                                            device.date_activation_interface
                                                ? moment(device.date_activation_interface).format(date_format)
                                                : $t('error_display.no_data')
                                        }}
                                    </span>
                                    <br />

                                    <span class="font-color-light ml-7">
                                        {{ $t('information_device_component.date_activation_device') }} :
                                    </span>
                                    <span class="font-weight-bold">
                                        {{
                                            device.date_activation_interface
                                                ? moment(device.date_activation_device).format(date_format)
                                                : $t('error_display.no_data')
                                        }}
                                    </span>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </v-slide-x-transition>
                </template>

                <template v-else>
                    {{ $t('asset_devices_information_widget_component.no_device_associated') }}
                </template>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/Equipment/Generic/Widget/WidgetWrapperComponent.vue'
import ChipsLastStatement from '@/components/Global/ChipsLastStatement.vue'
import displayHuman from '@/mixin/displayHuman'
import { nextTick } from 'vue'

export default {
    name: 'AssetDevicesInformationWidgetComponent',
    components: { ChipsLastStatement, WidgetWrapperComponent },
    mixins: [displayHuman],
    props: {
        devices: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected_device: 0,
            refresh: false,
        }
    },
    computed: {
        device() {
            return this.devices[this.selected_device]
        },
    },
    methods: {
        handleDeviceSelectUpdate() {
            this.refresh = true
            nextTick(() => {
                this.refresh = false
            })
        },
    },
}
</script>
