<template>
    <div id="details-tank-component">
        <v-row>
            <template v-for="n in [0, 1]">
                <v-col :key="n" cols="12" lg="6">
                    <container
                        :get-child-payload="n === 0 ? getLeftWidget : getRightWidget"
                        cols="6"
                        group-name="1"
                        non-drag-area-selector=".disable-drag"
                        style="min-height: 100px"
                        @drop="onDrop(n, $event)"
                        @drag-start="onDragStart"
                        @drag-end="onDragEnd"
                    >
                        <draggable v-for="(widget, $index) in widgets[n]" :key="$index" class="overflow-visible">
                            <div
                                v-if="widget.is_display "
                                class="mb-3 widget-grid"
                                data-cy="detail-asset-widget-wrapper"
                            >
                                <div class="background-white pt-5 px-1 rounded-l">
                                    <v-icon class="font-color-medium"> mdi-equal</v-icon>
                                </div>

                                <div class="disable-drag w-100">
                                    <template v-if="widget.widget_name === 'asset-card-widget-component'">
                                        <asset-card-widget-component
                                            :asset="tank"
                                            :is_open="widget.is_open"
                                            v-on:refresh:tank="$emit('refresh:tank')"
                                        />
                                    </template>

                                    <template v-if="widget.widget_name === 'oleo-asset-card-widget-component'">
                                        <oleo-asset-card-widget-component
                                            :asset="tank"
                                            :is_open="widget.is_open"
                                            v-on:refresh:tank="$emit('refresh:tank')"
                                        />
                                    </template>

                                    <template v-if="widget.widget_name === 'information-asset-component'">
                                        <information-asset-component :asset="tank" :is_open="widget.is_open" />
                                    </template>

                                    <template v-if="widget.widget_name === 'info-level-component'">
                                        <info-level-component :asset="tank" :is_open="widget.is_open" />
                                    </template>

                                    <template v-if="widget.widget_name === 'estimated-days-exhaustion-component'">
                                        <estimated-days-exhaustion-component :asset="tank" :is_open="widget.is_open" />
                                    </template>

                                    <template v-if="widget.widget_name === 'information-device-component'">
                                        <information-device-component
                                            v-for="device in tank.devices"
                                            :key="'information_device_' + device.id_device"
                                            :device="device"
                                            :is_open="widget.is_open"
                                        />
                                    </template>

                                    <template v-if="widget.widget_name === 'fullness-history-component'">
                                        <fullness-history-component :is_open="widget.is_open" :operations="tank.operations" />
                                    </template>

                                    <template v-if="widget.widget_name === 'consumption-report-component'">
                                        <!-- TODO: Just for apple store -> condition to remove -->
                                        <template v-if="tank.is_subscription_device_active">
                                            <consumption-report-component
                                                v-for="device in tank.devices"
                                                :key="'consumption_' + device.id_device"
                                                :id_asset="tank.id_asset"
                                                :id_device="device.id_device"
                                                :is_open="widget.is_open"
                                                :is_subscription_active="device.is_subscription_active"
                                            />
                                        </template>
                                    </template>

                                    <template v-if="widget.widget_name === 'chart-level-component'">
                                        <!-- TODO: Just for apple store -> condition to remove -->
                                        <template v-if="tank.is_subscription_device_active">
                                            <chart-level-component
                                                v-for="device in tank.devices"
                                                :key="'chart_' + device.id_device"
                                                :asset="tank"
                                                :device="device"
                                                :is_open="widget.is_open"
                                            />
                                        </template>
                                    </template>

                                    <template v-if="widget.widget_name === 'image-equipment-component'">
                                        <image-equipment-component
                                            :asset="tank"
                                            :is_open="widget.is_open"
                                            v-on:refresh:tank="$emit('refresh:tank')"
                                        />
                                    </template>

                                    <template v-if="widget.widget_name === 'economy-co2-component'">
                                        <economy-co2-component :id_asset="tank.id_asset" :is_open="widget.is_open" />
                                    </template>

                                    <template v-if="widget.widget_name === 'alerts-configured-component'">
                                        <alerts-configured-component :asset="tank" :is_open="widget.is_open" />
                                    </template>

                                    <template v-if="widget.widget_name === 'asset-localisation-widget-component'">
                                        <asset-localisation-widget-component :asset="tank" :is_open="widget.is_open" />
                                    </template>

                                    <template
                                        v-if="
                                            widget.widget_name ===
                                            'device-traited-data-by-device-referentiel-metric-datatable-widget-component'
                                        "
                                    >
                                        <device-traited-data-by-device-referentiel-metric-datatable-widget-component
                                            :device="tank.devices[0]"
                                            :is_open="widget.is_open"
                                        />
                                    </template>
                                </div>
                            </div>
                        </draggable>
                    </container>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import InformationAssetComponent from '@/components/Widget/InformationAssetComponent.vue'
import InfoLevelComponent from '@/components/Widget/InfoLevelComponent.vue'
import AlertsConfiguredComponent from '@/components/Widget/AlertsConfiguredComponent.vue'
import EstimatedDaysExhaustionComponent from '@/components/Widget/EstimatedDaysExhaustionComponent.vue'
import InformationDeviceComponent from '@/components/Widget/InformationDeviceComponent.vue'
import FullnessHistoryComponent from '@/components/Widget/FullnessHistoryComponent.vue'
import ConsumptionReportComponent from '@/components/Widget/ConsumptionReportComponent.vue'
import ChartLevelComponent from '@/components/Widget/ChartLevelComponent.vue'
import ImageEquipmentComponent from '@/components/Widget/ImageEquipmentComponent.vue'
import EconomyCo2Component from '@/components/Widget/EconomyCo2Component.vue'
import AssetCardWidgetComponent from '@/components/Widget/AssetCardWidgetComponent.vue'
import OleoAssetCardWidgetComponent from '@/components/Widget/Oleo100/OleoAssetCardWidgetComponent.vue'
import DeviceTraitedDataByDeviceReferentielMetricDatatableWidgetComponent from '@/components/Widget/DeviceTraitedDataByDeviceReferentielMetricDatatableWidgetComponent.vue'
import AssetLocalisationWidgetComponent from '@/components/Widget/AssetLocalisationWidgetComponent.vue'

export default {
    name: 'DetailsTankComponent',
    components: {
        AssetLocalisationWidgetComponent,
        DeviceTraitedDataByDeviceReferentielMetricDatatableWidgetComponent,
        OleoAssetCardWidgetComponent,
        AssetCardWidgetComponent,
        InfoLevelComponent,
        InformationAssetComponent,
        Container,
        Draggable,
        AlertsConfiguredComponent,
        EstimatedDaysExhaustionComponent,
        InformationDeviceComponent,
        FullnessHistoryComponent,
        ConsumptionReportComponent,
        ChartLevelComponent,
        ImageEquipmentComponent,
        EconomyCo2Component,
    },
    props: {
        tank: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.buildOrderConfigurationEquipment()
        //Watch body's class
        this.observer = new MutationObserver((mutations) => {
            for (const m of mutations) {
                const newValue = m.target.getAttribute(m.attributeName)
                this.$nextTick(() => {
                    this.onBodyClassChange(newValue, m.oldValue)
                })
            }
        })

        this.observer.observe(document.body, {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['class'],
        })
    },
    beforeDestroy() {
        this.observer.disconnect()
    },
    data() {
        return {
            widgets: [[], []],
            is_dragging: false,
        }
    },
    computed: {
        order_configuration_equipment: {
            get: function () {
                return this.$store.getters['page_order_configuration/order_configuration_equipment']
            },
            set: function (value) {
                this.$store.dispatch('page_order_configuration/getPageOrderConfigurationEquipment')
            },
        },
    },
    methods: {
        onBodyClassChange(classAttrValue) {
            //Wait for event onDragStart
            setTimeout(() => {
                //Fix mobile scroll when start dragging fail
                if (classAttrValue.includes('smooth-dnd-no-user-select') && this.is_mobile && !this.is_dragging) {
                    document.body.setAttribute('class', '')
                }
            }, 100)
        },
        onDragStart() {
            this.is_dragging = true
        },
        onDragEnd() {
            this.is_dragging = false
        },
        buildOrderConfigurationEquipment() {
            this.widgets = [[], []]
            this.order_configuration_equipment.forEach((configuration) => {
                if (configuration.col === 1) {
                    this.widgets[0].push(configuration)
                } else {
                    this.widgets[1].push(configuration)
                }
            })
        },
        getLeftWidget(index) {
            return this.widgets[0][index]
        },
        getRightWidget(index) {
            return this.widgets[1][index]
        },
        //Call for each drop-list in a group
        onDrop(collection, { removedIndex, addedIndex, payload }) {
            if (removedIndex !== null) {
                this.widgets[collection].splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                this.widgets[collection].splice(addedIndex, 0, payload)
            }

            if (collection === 1) {
                //Call on the last drop-list of the group to avoid duplicates or deletion widget
                this.updateOrCreatePageOrderEquipmentConfiguration()
            }
        },
        updateOrCreatePageOrderEquipmentConfiguration() {
            let order_configuration_equipment = []
            this.widgets[0].forEach((widget) => {
                widget.col = 1
                order_configuration_equipment.push(widget)
            })
            this.widgets[1].forEach((widget) => {
                widget.col = 2
                order_configuration_equipment.push(widget)
            })
            this.updateOrCreatePageOrderConfiguration(order_configuration_equipment, null)
        },
    },
    watch: {
        is_mobile: {
            handler() {
                this.buildOrderConfigurationEquipment()
            },
        },
        order_configuration_equipment: {
            handler() {
                this.buildOrderConfigurationEquipment()
            },
        },
    },
}
</script>

<style>
.widget-grid {
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr);
}
</style>